.left-box {
  flex: 3;
  max-width: 650px;
  /* 左边盒子占2份 */
  margin-right: 16px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  height: '100vh'
}

.right-container {
  display: flex;
  flex-direction: column;
  /* 纵向排列 */
  flex: 1;
  /* 右边盒子占3份 */
  gap: 16px;
  /* 盒子之间的间距 */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  height: '100vh'
}

.right-container .ant-card-body {
  padding: 8px;
  min-height: 80px;
  min-width: 200px;
}

.main-container {
  background-color: #fff;
}

/* 文章标题 */
.article-title {
  margin: 0 0 1.3rem;
  font-size: 2.667rem;
  font-weight: 600;
  line-height: 1.31;
  color: #252933;
}

.blog-pre {
  position: relative;
}

.markdown-navigation .title-anchor.active {
  /* background-color: #f8f8f8; */
  text-decoration: inherit;
}

.right-column {
  position: sticky;
  top: 20px;
  /* 距离顶部的偏移量，根据需要调整 */
  z-index: 10;
  /* 确保它在其他内容之上 */
  height: calc(100vh - 40px);
  /* 设置右侧栏的高度，使其适应视口 */
  overflow-y: auto;
  /* 让内容可滚动 */
}
.article-wrapper {
    display: flex;
    flex-direction: row;
    padding: var(--padding-md);
    margin-inline: var(--side-padding);
    /* 动态设置左右外边距 */
    gap: var(--gap-lg);
    background-color: var(--primary-bg-color);
}

.section {
    display: flex;
    padding: var(--padding-md);
}

.section-left {
    flex: 1;
    background-color: var(--card-bg-color);
    border-radius: var(--border-radius-lg);
    max-height: calc(100vh - 140px);
    border-radius: 8px;
}

.section-right {
    /* border: 1px solid saddlebrown; */
    flex: 2;
    flex-direction: column;
    gap: var(--gap-md);
    padding: 0%;
    /* background-color: var(--card-bg-color); */
    border-radius: var(--border-radius-lg);
    height: 100%;
    /* 自适应父级高度 */
}

.center {
    flex: 4;
    max-width: var(--max-content-width);
    width: 100%;
    background-color: var(--card-bg-color);
    border-radius: var(--border-radius-lg);
    display: flex;
    /* 使用 Flex 布局 */
    flex-direction: column;
    /* 垂直排列内容 */
}

.article-list {
    flex: 1;
    width: 100%;
    /* 占满父级宽度 */
}

.right-pane {
    /* padding: var(--padding-md); */
    background-color: var(--primary-bg-color);
    border-radius: var(--border-radius-lg);
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}

.title {
    color: #1890ff;
    text-decoration: none;
}

.title:hover {
    text-decoration: underline;
}
.header-container {
  padding: 0 clamp(10px, 5vw, 50px);
  /* 最小 10px，最大 50px，居中用 5vw 动态调整 */
  /* background: #001529; */
}

.header-content {
  display: flex;
  align-items: center;
  /* 保持垂直居中 */
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  /* 保持图片和文字垂直居中 */
  cursor: pointer;
}

.logo-img {
  height: 32px;
  margin-right: 8px;
}

.logo-text {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  margin-inline: 16px;
  line-height: 1;
  cursor: pointer;
}


.menu {
  flex: 1;
}

.header-actions {
  display: flex;
  align-items: center;
  /* 保持垂直居中 */
}

.avatar {
  background-color: #f56a00;
}
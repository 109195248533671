/* 基础CSS变量定义 */
:root {
  --primary-color: #1a73e8;
  /* --background-color: #f0f0f0; */
  --background-color: #f0f2f5;
  --text-color: #333;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --base-padding: 16px;
}

/* 深色模式设置 */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #222;
    --text-color: #e0e0e0;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 滚动条样式 */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

/* 链接样式 */
a {
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
}

/* 提示文本样式 */
.alert {
  padding: var(--base-padding);
  margin: var(--base-padding) 0;
  border-radius: 4px;
  background-color: #ffefc2;
  color: #805700;
}

.alert--error {
  background-color: #ffc2c2;
  color: #800000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  min-width: 40px;
  text-align: center;
  height: 40px;
  /* margin-inline-end: 24px; */
  margin-inline-end: 8px;
  cursor: pointer;
}
.edit-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: '100vh';
}

.edit-container .header {
  padding: 0 27px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.edit-container .header .header-left {
  flex: 1.8;
  width: 65%;
  line-height: 64px;
}

.edit-container .header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-left .input-title {
  padding: 0px;
  font-weight: 500;
  height: '100%';
  font-size: 24 !important;
}

.header-right button {
  margin-left: 16px;
}

.edit-container .btn {
  /* width: 0%; */
  /* display: inline-block; */
  width: 20%;
  /* background-color: azure; */
}

.save-status {
  font-size: 14px;
  white-space: nowrap;
  color: #c9cdd4;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}